.notifications {
  .page-header {
    margin-bottom: 0;
  }

  &__list {
    margin-bottom: $spacing-large;
    margin-left: -$spacing-small;

    &__notification {
      display: grid;
      grid-gap: 0;
      grid-row: 1;
      grid-template-columns: auto $spacing-large $spacing-medium;
      padding-left: $spacing-small;
      
      > div {
        padding-bottom: $spacing-small;
        padding-top: $spacing-small;
      }

      @media (min-width: $breakpoint-medium) {
        > div {
          padding-bottom: $spacing-small-plus;
          padding-top: $spacing-small-plus;
        }
  
      }

      &:hover {
        background-color: var(--light-gray);
        cursor: pointer;
      }
 
      &__unread-indicator {
        @include flex-row;

        align-items: center;
        border-bottom: none;
        color: var(--athena-blue-dark);
        justify-content: center;
        padding: 0 $spacing-small-minus;

        svg {
          height: 0.5rem;
          width: 0.5rem;
        }
      }

      &__time {
        align-items: center;
        border-bottom: $border-light;
        display: flex;
        justify-content: flex-end;
        white-space: nowrap;

        @include font--meta;

      }
  
      &__content {
        align-items: center;
        border-bottom: $border-light;
        display: flex;
        flex-direction: column;
        text-align: left;

        a {
          font-size: inherit;
        }

        p {
          @include font--meta;

          width: 100%;

          span {
            @include font--meta;

            color: var(--dark-gray);
            font-weight: $font-weight-medium;
            text-align: left;
            white-space: pre;
          }
          
          &.target {
            color: var(--athena-blue-darker);
            font-weight: $font-weight-medium;
          }
        }
      } 

      &:last-child {
        > div {
          border-bottom: none;
        }
      }

    }
  }  
}

.badge {
  background-color: var(--mid-gray);
  border-radius: $border-radius-large;
  color: var(--white);
  font-size: $font-size-tiny;
  font-weight: $font-weight-medium;
  padding: $spacing-tiny $spacing-tiny-plus;
  text-align: center;

  &-error {
    background-color: var(--red);
    color: var(--white);
  }

  &-success {
    background-color: var(--green);
    color: var(--white);
  }
}

.plans__list {
  display: flex;
  flex-wrap: nowrap;
  gap: $spacing-medium;
  justify-content: center;
  margin-top: $spacing-medium;
  width: 100%;
}

.plan {
  background-color: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: $border-radius-huge;
  box-shadow: 0 2px 4px var(--gray-shadow);
  padding: $spacing-medium;
  text-align: center;
  width: 30rem;

  h2 {
    font-size: $font-size-medium-plus;
    font-weight: $font-weight-medium;
    margin-bottom: $spacing-small;
  }

  p {
    font-size: $font-size-small;
    margin-bottom: $spacing-small;

    &.description {
      height: 5rem; // Set a fixed height for the description
      overflow: hidden; // Hide overflow text
    }

    &.price {
      color: var(--darker-gray);
      font-size: $font-size-large;
      font-weight: $font-weight-medium;
      margin-bottom: $spacing-medium;
    }
  
  }


  button {
    background-color: var(--athena-blue);
    border: none;
    border-radius: $border-radius-large;
    color: var(--white);
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-small;
    font-weight: $font-weight-medium;
    padding: $spacing-small $spacing-medium;
    text-align: center;
    text-decoration: none;

    &:hover {
      background-color: var(--athena-blue-dark);
    }
  }
}

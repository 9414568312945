.invitations {
  h2 {
    margin-bottom: $spacing-small-plus;

    &.subheading {
      font-family: $font-family-heading-secondary;
      font-size: $font-size-medium-plus;
    }
  }

  &__send-form {
    background-color: var(--lightest-gray);
    border: $border-light;
    border-radius: $border-radius-large;
    margin-bottom: $spacing-large;
    padding: $spacing-medium;
    width: 100%;

    &__form-group {
      display: flex;

      @include flex-row;

      input {
        border: $border-light;
        border-radius: $border-radius-medium;
        padding: $spacing-small
      }

      button {
        background-color: var(--athena-blue);
        border: none;
        border-radius: $border-radius-medium;
        color: var(--white);
        cursor: pointer;
        margin-left: $spacing-small;
        padding: $spacing-small-minus $spacing-medium;
        transition: background-color 0.3s;
        white-space: nowrap;
  
        &:hover {
          background-color: var(--athena-blue-dark);
        }
      }
    }
  }

  &__sent-list {    
    h2 {
      border-bottom: 1px solid var(--mid-light-gray);
      margin-bottom: 0;
      padding-bottom: $spacing-tiny-plus;
    }

    ul {
      padding-top: 0;
    }

    &__invite {
      border-bottom: $border-lighter;
      padding : $spacing-small-plus 0;
      
      &__info {
        align-items: center;
        display: grid;
        gap: 1rem;
        grid-template-columns: auto 1fr auto auto;

        &__status {
          grid-column: 1;

          .badge {
            min-width: 5rem;
          }
        }

        &__name {
          color: var(--dark-gray);
          grid-column: 2;

          span, a {
            font-size: $font-size-small-minus;

            &.expired {
              color: var(--red-dark);
            }  
          }
        }

        &__sent-ago {
          color: var(--mid-light-gray);
          font-size: $font-size-tiny;
          grid-column: 3;
          text-align: right;

          span {
            font-size: $font-size-small-minus;
          }
        }

        &__school {
          color: var(--darker-gray);
        }

        &__resend {
          grid-column: 4;
          text-align: right;
        }

      }
    }
  }
}

.accept-invitation {
  p {
    margin-bottom: $spacing-small;
    
    span, strong {
      font-size: $font-size-small-minus;
    }

    strong {
      color: var(--dark-gray);
      font-weight: $font-weight-bold;
    }
  }

  button {
    align-items: center;
    margin: $spacing-medium-plus 0;
    text-align: center;
  }
}

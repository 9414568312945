$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-family-heading-primary: "kepler-std-display", "serif";
$font-family-heading-secondary: "aktiv-grotesk", "sans-serif";
$font-family-body: "aktiv-grotesk", "sans-serif";
$font-family-tertiary: "aktiv-grotesk", "sans-serif";

$font-size-tiny-minus: 0.6rem;
$font-size-tiny: 0.7rem;
$font-size-tiny-plus: 0.8rem;
$font-size-small-minus: 0.9rem;
$font-size-small: 1rem;
$font-size-small-plus: 1.1rem;
$font-size-medium-minus: 1.2rem;
$font-size-medium: 1.3rem;
$font-size-medium-plus: 1.4rem;
$font-size-large-minus: 1.7rem;
$font-size-large: 2.2rem;
$font-size-large-plus: 2.7rem;
$font-size-huge-minus: 3rem;
$font-size-huge: 3.5rem;

$line-height-small-minus: 1;
$line-height-small: 1.1;
$line-height-small-plus: 1.2;
$line-height-medium-minus: 1.3;
$line-height-medium: 1.4;
$line-height-medium-plus: 1.5;
$line-height-large-minus: 1.6;
$line-height-large: 1.7;
$line-height-large-plus: 1.8;
$line-height-huge: 2;

@mixin font--heading-primary {
  font-family: $font-family-heading-primary;
  font-size: $font-size-large-minus;
  font-weight: $font-weight-regular;
  line-height: $line-height-small;

  @media (min-width: $breakpoint-small) {
    font-size: $font-size-large;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-huge-minus;
  }
}

@mixin font--heading-item-title {
  font-family: $font-family-heading-primary;
  font-size: $font-size-large-minus;
  font-weight: $font-weight-medium;
  line-height: $line-height-small;

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-large;
  }
}

@mixin font--heading-discussion-title {
  font-family: $font-family-heading-primary;
  font-size: $font-size-medium-minus;
  font-weight: $font-weight-medium;
  line-height: $line-height-medium-minus;

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-large-minus;
  }
}

@mixin font--heading-secondary {
  font-family: $font-family-heading-secondary;
  font-size: $font-size-small-plus;
  font-weight: $font-weight-medium;
  line-height: $line-height-medium;
}

@mixin font--body {
  font-family: $font-family-body;
  font-size: $font-size-small;
  line-height: $line-height-large-minus;
}

@mixin font--tertiary {
  font-family: $font-family-tertiary;
  font-size: $font-size-small-minus;
  font-weight: $font-weight-regular;

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-small;
  }
}

@mixin font--meta {
  color: var(--mid-gray);
  font-family: $font-family-tertiary;
  font-size: $font-size-tiny-plus;
  font-weight: $font-weight-light;
  line-height: $line-height-medium-plus;

  @media (min-width: $breakpoint-small) {
    font-size: $font-size-small-minus;
  }
}

@mixin font--label {
  font-family: $font-family-tertiary;
  font-size: $font-size-small-minus;
  font-weight: $font-weight-medium;
  line-height: $line-height-medium;

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-small;
  }
}


.action-button {
  background-color: var(--white);
  border: $border-light;
  border-radius: $border-radius-medium;
  color: var(--dark-gray);
  direction: ltr;
  font-size: $font-size-small-minus;
  font-weight: $font-weight-regular;
  padding: 0;
  user-select: none;

  svg {
    height: 0.8rem;
    margin-top: $spacing-tiny-minus;
    width: 0.8rem;
  }

  span {
    font-size: $font-size-small-minus;
    line-height: $line-height-large-plus;
  }

  &__icon {
    @include flex-row;

    align-items: center;
    padding: 0 $spacing-tiny-plus
  }

  &__count {
    margin-left: $spacing-tiny;
  }

  &__label {
    border-left: $border-light;
    color: var(--dark-gray);
    padding: 0 $spacing-small-minus;
  }

  &:hover {
    background-color: var(--lightest-gray);
    color: var(--darker-gray);

    span {
      color: var(--black);
    }
  }

  &__active {
    background-color: var(--lightest-gray);

    &:hover {
      background-color: var(--lightest-gray);
      color: var(--darker-gray);
    }

    span {
      background-color: var(--lightest-gray);
      color: var(--black);
    }
  }

  &__disabled {
    color: var(--light-gray);
    
    &:hover, &:hover span, span {
      background-color: transparent;
      color: var(--light-gray);
      cursor: not-allowed;
    }
  }

  &.like {
    color: var(--red);

    &:hover {
      color: var(--red-dark);
    }

    &.action-button__active span.action-button__icon {
      color: var(--red);

      &:hover {
        color: var(--red-dark);
      }
    }

    &.action-button__active span.action-button__count {
      color: var(--red);

      &:hover {
        color: var(--red-dark);
      }
    }
  }

  &.bookmark {
    color: var(--mid-gray);

    &:hover {
      color: var(--dark-gray);
    }

    &.action-button__active span.action-button__icon {
      color: var(--mid-gray);

      &:hover {
        color: var(--darker-gray);
      }
  
    }

    &.action-button__active span.action-button__count {
      color: var(--mid-gray);

      &:hover {
        color: var(--darker-gray);
      }
    }
  }

  &.follow {
    color: var(--yellow);

    &:hover {
      color: var(--yellow-dark);
    }

    &.action-button__active span.action-button__icon {
      color: var(--yellow);

      &:hover {
        color: var(--yellow-dark);
      }
    }
  }
}


.menu-button-container {
  outline: none;
  position: relative;
  
  &:focus-within .selection-list {
    display: block;
  }

  .action-button {
    .selection-list:focus-within &,
    &:hover {
      background-color: var(--lightest-gray);
      color: var(--black);
    }
  }

  .selection-list {
    box-shadow: var(--box-shadow);
    display: none;
    left: 0;
    position: absolute;
    top: $spacing-medium;
  
    @media (min-width: $breakpoint-small) {
      left: unset;
      right: 0;
    }
  
    &__menu {
      background-color: var(--white);
      border: $border-light;
      border-radius: $border-radius-medium;
      outline: none;
      width: 16rem;
  
      &__option {
        align-items: center;
        background-color: transparent;
        border-bottom: $border-light;
        color: var(--dark-gray);
        font-size: $font-size-small-minus;
        justify-content: flex-start;
        padding: $spacing-tiny-plus $spacing-small $spacing-tiny-plus $spacing-small;
        text-align: left;
        width: 100%;
  
        &:last-child {
          border-bottom: none;
        }
  
        &:hover {
          background-color: var(--lightest-gray);
          color: var(--black);
        }
  
        &:focus {
          background-color: var(--lightest-gray);
          color: var(--black);
          outline: none;
        }
  
        &__selected-icon {
          @include flex-row;
  
          margin-right: $spacing-small-minus;
          
          svg {
            height: $spacing-small;
            left: $spacing-tiny;
            width: $spacing-small;
          }
        }
  
        &__label {
          font-size: $font-size-small-minus;
          width: 90%;
        }
  
        &:disabled {
          background-color: var(--gray);
          color: var(--light-gray);
  
          &:hover {
            background-color: transparent;
            color: var(--light-gray);
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

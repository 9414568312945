@mixin meta-label--shared {
  @include flex-row;

  border-radius: $border-radius-medium;
  color: var(--mid-gray);
  height: min-content;
  user-select: none;
  white-space: nowrap;
  width:100%;

  svg {
    height: $spacing-small;
    width: $spacing-small;
  }

  span {
    font-size: $font-size-tiny-plus;
    font-weight: $font-weight-regular;
    line-height: $line-height-small;
    margin: 0 0 0.1rem $spacing-tiny;

    .items-list.action-hover & {
      display: none;
      
    }

    .items-list.action-hover .items-list__item:hover & {
      display: block;
    }

  }
}

.meta-label--fixed-width {
  align-items: center;
  display: flex;
  justify-content: center;
  width: $spacing-medium-plus;
}

.meta-label--no-actions div {
  opacity: 0.2;

  &.meta-label--flags {
    opacity: 0;
  }
}

.meta-label--comments {
  @include meta-label--shared;
}

.meta-label--bookmarks {
  @include meta-label--shared;

  color: var(--mid-gray);
  display: none;

  .items-list.action-hover & {
    display: flex;
  }
}

.meta-label--likes {
  @include meta-label--shared;

  color: var(--red);
}

.meta-label--groups {
  @include meta-label--shared;

  display: none;

  .items-list.action-hover & {
    display: flex;
  }
}

.meta-label--shares {
  @include meta-label--shared;

  display: none;

  .items-list.action-hover & {
    display: flex;
  }
}

.meta-label--flags {
  @include meta-label--shared;

  color: var(--yellow);
  display: none;

  .items-list.action-hover & {
    display: flex;
  }
}

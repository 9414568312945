$black: #0e0e0e;
$white: #fff;

:root {
  --black: #0e0e0e;
  --darker-gray: #333;
  --dark-gray: #444;
  --mid-gray: #757575;
  --mid-lightish-gray: #959595;
  --mid-light-gray: #bdbdbd;
  --light-gray: #d9d9d9;
  --lighter-gray: #e8e8e8;
  --lightest-gray: #f3f3f3;
  --faint-gray: #f8f8f8;
  --white: #fff;
  --green: #1c812e;
  --green-dark: #114f1c;
  --green-darker: #0b3814;
  --red: #af2b1d;
  --red-dark: #9c2b1f;
  --red-darker: #832016;
  --yellow: #ebc700;
  --yellow-dark: #bfa700;
  --gray-shadow: rgba(0, 0, 0, 0.1);
  --athena-blue: #1d78af;
  --athena-blue-light: #92cbed;
  --athena-blue-lighter: #deeffa;
  --athena-blue-lightest: #e9f5fb;
  --athena-blue-dark: #196999;
  --athena-blue-darker: #165a83;
  --athena-blue-darkest: #104768;
  --athena-light-teal: #e4f4f5;
  --athena-light-teal-dark: #bfe5e7;
  --athena-orange: #a36a24;

  .darkmode {
    --black: #fff;
    --darker-gray: #eee;
    --dark-gray: #aeaeae;
    --mid-gray: #919191;
    --mid-light-gray: #515151;
    --light-gray: #353535;
    --lighter-gray: #151515;
    --lightest-gray: #111;
    --faint-gray: #0f0f0f;
    --white: #08080a;
    --red: #fc200d;
    --red-dark: #ff594a;
    --red-darker: #ff5c60;
    --yellow: #ffea00;
    --yellow-dark: #e6c200;
    --gray-shadow: rgba(255, 255, 255, 0.1);
    --athena-blue: #3a9afb;
    --athena-blue-light: #15304d;
    --athena-blue-lighter: #102439;
    --athena-blue-lightest: #0d1d2e;
    --athena-blue-dark: #6bb5ff;
    --athena-blue-darker: #76baff;
    --athena-light-teal: #112b2d;
    --athena-light-teal-dark: #205458;
    --athena-orange: #d69231;
  }
}

.search-bar-inner {
  position: relative;

  .input-with-icon {
    border: $border-light;
  }

  input {
    font-size: $font-size-small;
    font-weight: $font-weight-regular;

    @media (min-width: $breakpoint-small) {
      font-size: $font-size-small-plus;
    }

    &::-webkit-search-cancel-button {
      cursor: pointer;
    }

    &::placeholder {
      font-weight: $font-weight-regular;
    }
  }

  &__results-container {
    border: $border-light;
    border-radius: 0 0 $border-radius-large $border-radius-large;
    box-shadow: var(--box-shadow);
    display: block;
    overflow: hidden;
    position: absolute;
    top: calc(#{$spacing-large} - 1px);
    width: 100%;
    z-index: $z-index-level-2;

    @media (min-width: $breakpoint-large) {
      display: block;
    }
  }

  &__results {
    background-color: var(--white);
    display: grid;
    grid-gap: $spacing-small-plus;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: $spacing-small-plus 0;
    width: 100%;

    h3 {
      color: var(--athena-orange);
      font-size: $font-size-small-minus;
      padding: 0 $spacing-small-plus;
    }

    li {
      border-bottom: $border-light;
      line-height: $line-height-small-plus;

      &.selected {
        background-color: var(--lightest-gray);

        a {
          color: var(--athena-blue);
        }
      }

      a {
        color: var(--dark-gray);
        display: block;
        font-size: $font-size-small;
        padding: $spacing-small-minus $spacing-small-plus;

        &:hover {
          background-color: var(--lightest-gray);
          color: var(--athena-blue);
          text-decoration: none;
        }

        &:focus {
          outline-offset: -3px;
        }

        @media (min-width: $breakpoint-medium) {
          font-size: $font-size-small-plus;
          padding: $spacing-small $spacing-small-plus;
        }
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @media (min-width: $breakpoint-medium) {
      flex-direction: column;
    }
  }

  .search-bar-inner__secondary {
    margin-left: $spacing-tiny-plus;

    .search-bar-inner__results a:hover &,
    .search-bar-inner__results li.selected & {
      color: var(--darker-gray);
    }
  }

  &__results-section {
    display: grid;
    grid-gap: $spacing-tiny-plus;
  }
}

.search-bar--nav {
  @include flex-row;

  align-items: center;
  height: 100%;
  margin: 0 auto 0 $spacing-tiny-minus;
  padding-left: $spacing-tiny-minus;
  width: 90%;

  .search-bar__toggle-button {
    &-container {
      @include flex-row;

      align-items: center;
      height: 100%;

      button {
        height: 100%;
        padding: 0 $spacing-small;

        &:hover,
        &:focus {
          background-color: var(--lightest-gray);
          outline: none;
        }
      }

      @media (min-width: $breakpoint-large) {
        display: none;
      }
    }

    &-inner {
      align-items: center;
      color: var(--darker-gray);
      display: grid;
      grid-gap: $spacing-small-minus;
      grid-template-columns: min-content max-content;
      line-height: $line-height-medium-plus;

      svg {
        height: $spacing-small-plus;
        width: $spacing-small-plus;
      }
    }
  }

  &__overlay {
    display: none;

    @media (max-width: $breakpoint-large) {
      background-color: $black;
      bottom: 0;
      color: var(--white);
      display: block;
      left: 0;
      opacity: 0.9;
      position: fixed;
      right: 0;
      top: 0;
      z-index: $z-index-level-3;

      &-close-button {
        color: $white;
        position: relative;
        text-align: center;
        top: $spacing-tiny-plus;
        width: 100%;

        span {
          cursor: pointer;
        }
      }
    }
  }

  .search-bar-inner {
    display: none;
    width: 100%;

    @media (min-width: $breakpoint-large) {
      display: block;
    }

    &--visible {
      @media (max-width: $breakpoint-large) {
        background-color: var(--white);
        border-radius: $border-radius-medium;
        box-shadow: var(--box-shadow);
        display: block;
        left: calc(92.5vw / 25);
        position: fixed;
        top: 3rem;
        width: 92.5vw;
        z-index: $z-index-level-4;
      }

      input {
        padding:
          $spacing-small $spacing-small $spacing-small
          $spacing-small-minus;

        @media (min-width: $breakpoint-large) {
          padding: $spacing-small-minus;
        }
      }
    }

    .input-with-icon {
      border: none;
      height: 100%;

      svg {
        height: $spacing-small;
        width: $spacing-small;

        @media (min-width: $breakpoint-small) {
          height: $spacing-small-plus;
          width: $spacing-small-plus;
        }
      }
    }

    input {
      font-size: $font-size-small-minus;

      @media (min-width: $breakpoint-small) {
        font-size: $font-size-small;
      }
    }

    &__results-container {
      border: none;
      border-radius: 0;
      border-top: $border-light;
      position: static;

      @media (min-width: $breakpoint-large) {
        border: $border-light;
        border-radius: 0 0 $border-radius-medium $border-radius-medium;
        border-top: none;
        position: absolute;
        top: calc(#{$spacing-medium-plus} - 2px);
      }
    }

    &__results {
      padding: $spacing-small-minus 0;

      a {
        font-size: $font-size-small-minus;
        padding: $spacing-small-minus $spacing-small-plus;
      }
    }
  }
}

.topic {
  &__content {
    display: grid;
    grid-gap: $spacing-medium-minus;

    @media (min-width: $breakpoint-large-plus) {
      grid-gap: $spacing-large;
      grid-template-columns: minmax(14rem, min-content) 3fr;
    }
  }

  &__discussions {
    display: grid;
    grid-template-rows: min-content 1fr;
  }

  &__search-and-button {
    display: grid;
    grid-gap: $spacing-small;
  }

  &__filters {
    display: grid;
    grid-gap: $spacing-medium-minus;
    height: min-content;

    @media (min-width: $breakpoint-large-plus) {
      grid-gap: $spacing-medium;
    }
  }

  &__filter--dropdown {
    @media (min-width: $breakpoint-large-plus) {
      display: none;
    }

    .topic__filters.no-content & {
      display: none;
    }
  }

  &__filter--radio {
    display: none;

    @media (min-width: $breakpoint-large-plus) {
      display: block;
    }
  }

  &__filter--radio-container {
    border-radius: $border-radius-medium $border-radius-medium 0 0;

    @media (min-width: $breakpoint-large-plus) {
      background-color: var(--faint-gray);
    }

    legend {
      background-color: var(--lighter-gray);
      border-radius: $border-radius-medium $border-radius-medium 0 0;
      color: var(--black);
      font-size: $font-size-small;
      line-height: $line-height-medium-plus;
      margin-bottom: 0;
      padding: $spacing-tiny-plus $spacing-small;
      width: 100%;
    }
  }

  &__filter-options {
    @media (min-width: $breakpoint-large-plus) {
      border-radius: 0 0 $border-radius-medium $border-radius-medium;
    }
  }

  &__filter-radio {    
    input:focus ~ label {
      outline-color: var(--mid-gray);
    }

    input:checked ~ label {
      background-color: var(--athena-blue-dark);
      color: var(--white);
    }

    label {
      border-bottom: $border-transparent;
      color: var(--dark-gray);
      font-size: $font-size-small-minus;
      line-height: $line-height-medium-minus;
      margin: 0;
      padding: $spacing-tiny-plus $spacing-small;

      &:hover {
        background-color: var(--light-gray);
        color: var(--black);
      }
    }
  }

  &__filter-bar-and-items {
    display: grid;
    grid-template-rows: min-content auto;
  }

  &__search-bar {
    display: grid;
    grid-gap: $spacing-medium;
  }

  &__search {
    input {
      font-size: $font-size-small-minus;

      @media (min-width: $breakpoint-small) {
        font-size: $font-size-small;
      }
    }

    svg {
      color: var(--mid-gray);
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  &__verified-filter-container {
    @include flex-column;

    align-items: center;
    background-color: var(--faint-gray);
    justify-content: center;
    padding: $spacing-small;

    legend {
      float: left;
      margin: 0 0 $spacing-small 0;
      text-align: center;
    }

    label {
      margin: 0;
    }

    @media (min-width: $breakpoint-small) {
      @include flex-row;

      legend {
        margin: 0 $spacing-small 0 0;
      }
    }

    @media (min-width: $breakpoint-large-plus) {
      display: block;

      legend {
        margin: 0 0 $spacing-small 0;
        text-align: left;
      }
    }
  }

  &__filters-too-strict {
    margin-bottom: $spacing-large;

    p {
      margin-bottom: $spacing-small;
    }

    button {
      margin-bottom: $spacing-small;
    }
  }
}

.topics-list {
  li {
    @include flex-row;

    align-items: center;
    border-bottom: $border-light;
    justify-content: space-between;
    padding: $spacing-small 0;

    a {
      font-size: $font-size-small-plus;
      margin-right: $spacing-small;
      width: fit-content;

      @media (min-width: $breakpoint-medium) {
        font-size: $font-size-medium-minus;
      }
    }

    span {
      background-color: var(--lighter-gray);
      color: var(--dark-gray);
      padding: $spacing-tiny-minus $spacing-tiny-plus;
      white-space: nowrap;
    }
  }
}

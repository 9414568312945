
:root {
  --icon-teacher-dark: url("/src/assets/teacher-dark.png");
  --icon-teacher-light: url("/src/assets/teacher-light.png");
  --icon-school-leader-dark: url("/src/assets/school-leader-dark.png");
  --icon-school-leader-light: url("/src/assets/school-leader-light.png");
  --icon-provider-dark: url("/src/assets/provider-dark.png");
  --icon-provider-light: url("/src/assets/provider-light.png");
}

.darkmode {
  --icon-teacher-dark: url("/src/assets/teacher-light.png");
  --icon-teacher-light: url("/src/assets/teacher-dark.png");
  --icon-school-leader-dark: url("/src/assets/school-leader-light.png");
  --icon-school-leader-light: url("/src/assets/school-leader-dark.png");
  --icon-provider-dark: url("/src/assets/provider-light.png");
  --icon-provider-light: url("/src/assets/provider-dark.png");
}
.account {
  display: grid;
  grid-gap: $spacing-medium;
  
  &__subscription {
    button {
      margin-top: $spacing-small;
    }
  }  
}

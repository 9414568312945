.comments-section {
  margin-top: $spacing-small-plus;

  @media (min-width: $breakpoint-huge-minus) {
    background-color: var(--faint-gray);
    border-radius: $border-radius-medium;
    margin-top: 0;
    overflow-y: auto;
    padding: $spacing-medium-minus;
    position: sticky;
    top: $spacing-medium;
  }

  &.comments-section--item {
    @media (min-width: $breakpoint-huge-minus) {
      border: $border-lighter;
      height: calc(90vh - 6rem);
      overflow-y: auto;
    }
  }

  &__inner {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: $spacing-medium;

    h2 {
      font-size: $font-size-small-minus;

      @media (min-width: $breakpoint-medium) {
        font-size: $font-size-small;
      }
    }

    @media (min-width: $breakpoint-huge-minus) {
      padding-bottom: $spacing-small;
    }
  }

  textarea {
    height: 6rem;
    width: 100%;
  }

  p a {
    text-decoration: none;
  }

  p,
  a,
  textarea,
  li {
    font-size: $font-size-small-minus;

    @media (min-width: $breakpoint-medium) {
      font-size: $font-size-small;
    }

    @media (min-width: $breakpoint-huge-minus) {
      font-size: $font-size-small-minus;
    }
  }

  &__requesting-feedback {
    background-color: var(--athena-light-teal);
    border: $border-teal;
    display: grid;
    grid-gap: $spacing-tiny;
    padding: $spacing-small;

    h3 {
      font-weight: $font-weight-regular;
    }
  }

  &__heading-and-input {
    display: grid;
    grid-gap: $spacing-small-minus;
  }

  .comments-list {
    display: grid;
    grid-gap: $spacing-medium;

    &__comment {
      display: grid;
      grid-gap: $spacing-small-plus;

      @media (min-width: $breakpoint-huge-minus) {
        grid-gap: $spacing-small-minus;
      }
    }

    &__secondary-comments-list {
      border-left: $border-lighter;
    }

    &__comment-secondary {
      padding: $spacing-tiny-plus 0 $spacing-tiny-plus $spacing-medium-minus;

      @media (min-width: $breakpoint-large) {
        padding:
          $spacing-small-minus 0 $spacing-small-minus
          $spacing-medium-minus;
      }
    }

    &__comment-body-and-author {
      display: grid;
      grid-gap: $spacing-tiny-plus;

      .paragraph-container {
        display: grid;
        grid-gap: $spacing-small;
      }
    }

    &__author a {
      font-weight: $font-weight-medium;
    }

    &__reply-form {
      display: grid;
      grid-gap: $spacing-small-minus;

      button {
        width: fit-content;
      }
    }

    &__reply-button {
      color: var(--mid-gray);

      &:hover {
        color: var(--dark-gray);
        text-decoration: underline;
      }

      svg {
        height: 0.7rem;
        margin-left: $spacing-small-minus;
        width: 0.7rem;
      }
    }

    &__reply-buttons {
      @include flex-row;

      *:last-child {
        margin-left: $spacing-small;
      }
    }
  }
}

.comments-section--discussion {
  border-top: $border-light;
  display: grid;
  grid-gap: $spacing-medium-plus;
  margin-top: 0;
  padding-top: $spacing-medium-minus;

  @media (min-width: $breakpoint-huge-minus) {
    background-color: transparent;
    overflow: visible;
    padding: 0;
    padding-top: $spacing-medium-minus;
    position: static;
  }

  textarea {
    height: 8rem;
  }

  p,
  a,
  textarea,
  li {
    font-size: $font-size-small-minus;

    @media (min-width: $breakpoint-medium) {
      font-size: $font-size-small;
    }
  }

  .comments-list {
    margin-left: $spacing-small-plus;

    &__comment {
      @media (min-width: $breakpoint-huge-minus) {
        grid-gap: $spacing-small-plus;
      }
    }

    &__reply-form {
      border-top: $border-light;
      padding-top: $spacing-medium;
    }

    &__reply-button {
      font-size: $font-size-small-minus;

      @media (min-width: $breakpoint-huge-minus) {
        font-size: $font-size-small;
      }

      svg {
        height: 0.8rem;
        margin-left: $spacing-small-minus;
        width: 0.8rem;
      }
    }
  }
}

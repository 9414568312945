html {
  font-family: $font-family-body;
  font-size: 16px;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  background-color: var(--black);
  color: var(--black);
  min-height: 100vh;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: $outline;
    outline-offset: $outline-offset;
    transition: none;
  }
}

pre {
  margin: 0;
  white-space: pre-wrap;
}

.page {
  align-items: start;
  display: grid;
  grid-gap: $spacing-medium-minus;
  margin: 0 auto;

  @media (min-width: $breakpoint-large) {
    grid-gap: $spacing-large-plus;
  }

  @media (min-width: $breakpoint-huge-minus) {
    grid-template-columns: 1fr;

    &.has-sidebar {
      grid-template-columns: 5fr 3fr;
    }
  }  
}
/* Add this to your CSS file */
.premium-indicator {
  color: red;
  font-weight: bold;
}

.premium-overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.overlay-content {
  background: white;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}
.container {
  &--main {
    @include flex-column;

    background-color: var(--white);
    min-height: 100vh;
  }

  &--outer {
    align-self: center;
    flex: 1;
    margin: 0 auto;
    max-width: 90rem;
    width: 100%;
  }

  &--inner {
    padding: $spacing-small-plus $spacing-small;

    @media (min-width: $breakpoint-medium) {
      padding: $spacing-medium $spacing-large-minus;
    }
  }

  &--centered {
    margin: 0 auto;
    max-width: 35rem;
  }

  &--centered-wide {
    margin: 0 auto;
    max-width: 45rem;
  }

  &--centered-wider {
    margin: 0 auto;
    max-width: 65rem;
  }

  &--centered-widest {
    margin: 0 auto;
    max-width: 80rem;
  }

  &--home {
    margin: $spacing-small auto 0;
    max-width: 50rem;
  }

  &__page-not-found {
    display: grid;
    grid-gap: $spacing-medium;
  }
}

.alert-container {
  background-color: var(--lightest-gray);
  border: $border-error;
  padding: $spacing-small-plus $spacing-medium-minus;
}

.info-container {
  background: var(--athena-blue-lighter);
  border: 1px solid var(--athena-blue-light);
  justify-self: center;
  max-width: 40rem;
  padding: $spacing-tiny-plus $spacing-large;
  text-align: center;
}

.admin-banner {
  background-color: var(--yellow);
  color: var(--darker-gray);
  font-family: "Fira Code", "Source Code Pro", Courier, monospace;
  font-size: $font-size-tiny;
  padding: $spacing-tiny-plus $spacing-small;
  text-align: center;
  width: 100%;
  z-index: 1000;

  strong {
    font-weight: $font-weight-bold;
    letter-spacing: 0.1rem;
  }
}

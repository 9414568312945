.items-list {
  &__subtopics-list {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: $spacing-medium;
  }

  &__topic {
    color: var(--athena-blue);
    display: inline;
    font-size: $font-size-small-plus;

    @media (min-width: $breakpoint-medium) {
      font-size: $font-size-medium;
    }
  }

  &__subtopic {
    color: var(--athena-orange);
    font-size: $font-size-small;
    margin-bottom: -$spacing-small-minus;

    li:first-of-type & {
      margin-top: $spacing-small;
    }
  }

  &__item-title {
    a {
      color: var(--black);
      font-size: $font-size-small-minus;
      font-weight: $font-weight-medium;

      &:hover {
        color: var(--athena-blue);
        text-decoration: none;
      }

      @media (min-width: $breakpoint-medium) {
        font-size: $font-size-small-plus;
      }
    }
  }

  &__item-title-and-verified-badge {
    align-items: center;
    display: grid;
    grid-gap: $spacing-small-minus;
    grid-template-columns: auto 1fr;
  }

  &__verified-badge-container {
    position: relative;
  }

  &__verifying-authorities {
    background-color: var(--white);
    border: $border-light;
    border-radius: $border-radius-medium;
    box-shadow: var(--box-shadow);
    display: none;
    margin-top: $spacing-small-minus;
    padding: $spacing-tiny-plus $spacing-small $spacing-tiny-plus $spacing-small;
    position: absolute;
    width: $spacing-huge-plus * 3;
    z-index: $z-index-level-4;

    &:focus-within, &:hover {
      display: block;
    }

    @media (min-width: $breakpoint-small) {
      left: 0;
    }

    p {
      color: var(--dark-gray);
    }
  }


  &__item-verified-badge {
    align-items: center;
    background-color: var(--athena-blue-dark);
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: $spacing-medium-minus - $spacing-tiny;
    justify-content: center;
    width: $spacing-medium-minus - $spacing-tiny;

    &:focus + .items-list__verifying-authorities {
      display: block;
    }

    svg {
      color: var(--white);
      height: $spacing-small - $spacing-tiny-minus;
      width: $spacing-small - $spacing-tiny-minus;
    }
  }

  &__item {
    border-bottom: $border-light;
    display: grid;
    grid-gap: $spacing-tiny-plus;
    padding: $spacing-small 0;

    @media (min-width: $breakpoint-medium) {
      padding: $spacing-medium-minus 0;
    }
  }

  &__meta {
    align-items: center;
    display: grid;
    grid-gap: $spacing-small-plus;

    a {
      font-size: inherit;
    }

    @media (min-width: $breakpoint-medium) {
      grid-gap: $spacing-small;
      grid-template-columns: auto auto;
      justify-content: space-between;
    }
  }

  &__meta-left {
    line-height: $line-height-small;

    span {
      line-height: $line-height-small;
    }
  }

  &__meta-right {
    align-items: center;
    display: grid;
    grid-auto-columns: min-content;
    grid-gap: $spacing-small;
    width: 100%;

    * {
      grid-row: 1;
    }

    @media (width <= $breakpoint-medium) {
      justify-content: end;
    }
  }
}

.items-list--discussions {
  &.items-list {
    display: block;
    grid-gap: 0;
    grid-template-rows: unset;
  }
}

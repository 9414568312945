.discussion {
  display: grid;
  grid-gap: $spacing-medium-minus;

  p {
    font-size: $font-size-small-minus;

    @media (min-width: $breakpoint-small) {
      font-size: $font-size-small;
    }
  }

  &__question-content {
    display: grid;
    grid-gap: $spacing-small-plus;
    padding-bottom: $spacing-large;
  }

  &__header {
    display: grid;
    grid-gap: $spacing-small;
  }

  &__meta {
    display: grid;
    grid-gap: $spacing-tiny-plus;
  }

  &__header-title-and-delete h1 {
    display: inline;
    margin-right: $spacing-small;
  }

  &__edit {
    display: inline;
  }

  &__request-delete {
    align-items: flex-end;
    color: var(--red);
    display: inline-grid;
    grid-auto-columns: auto;
    grid-gap: $spacing-small-minus;

    * {
      grid-row: 1;
    }
  }

  &__confirm-delete {
    background-color: var(--faint-gray);
    border: $border-lighter;
    display: grid;
    grid-gap: $spacing-small;
    margin-top: $spacing-small;
    padding: $spacing-small-plus;
  }

  &__confirm-delete-buttons {
    align-items: center;
    display: grid;
    grid-auto-columns: max-content;
    grid-gap: $spacing-small;

    * {
      grid-row: 1;
      height: 100%;
    }
  }
}

button.discussion__create-button {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin-left: auto;
  width: 100%;

  @media (min-width: $breakpoint-large) {
    width: auto;
  }
}

.signup {
  h2.form__step-title {
    color: var(--dark-gray);
    font-size: $font-size-medium-plus;
    font-style: italic;
    margin-bottom: $spacing-small;
    text-align: center;
  }

  .form__terms-checkbox {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    label {
      margin-bottom: 0;
    }
  }

  .form__terms-arbitration-message {
    display: none;

    p {
      color: var(--mid-light-gray);
      font-size: $font-size-tiny;  
    }
  }
  
  .role-radio {
    margin-left: 10%;
    padding-top: $spacing-small-minus;
    width: 80%;

    label {
      align-items: center;
      background-color: var(--light-gray);
      display: flex;
      padding: $spacing-small $spacing-medium $spacing-small $spacing-tiny-plus;
      text-align: left;
      transition: background-color 0.3s ease, color 0.3s ease;

      h3, p {
        transition: color 0.3s ease;
      }

      h3 {
        font-size: $font-size-small-plus;
      }

      &:hover {
        background-color: var(--athena-blue-light);
        cursor: pointer;
      }

      &:active {
        background-color: var(--athena-blue-dark);
        transition: background-color 0.3s ease, color 0.3s ease;
      }
    }

    input[type="radio"]:checked + label {
      background-color: var(--athena-blue-dark);

      h3, p {
        color: var(--white);
        transition: color 0.3s ease;
      }

      .role-radio__image {
        .role-radio__image--dark {
          opacity: 0;
          transition: opacity 0.3s ease;
        }

        .role-radio__image--light {
          opacity: 1;
          transition: opacity 0.3s ease;
        }
      }
    }

    &__image {
      height: $spacing-huge-plus;
      position: relative;
      width: $spacing-huge-plus;

      .role-radio__image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s ease;
        width: 100%;

        &--dark {
          opacity: 1;

          &.teacher {
            background-image: var(--icon-teacher-dark);
          }

          &.school-leader {
              background-image: var(--icon-school-leader-dark);
          }

          &.provider {
            background-image: var(--icon-provider-dark);
          }
        }

        &--light {
          opacity: 0;

          &.teacher {
            background-image: var(--icon-teacher-light);
          }

          &.school-leader {
              background-image: var(--icon-school-leader-light);
          }
  
          &.provider {
            background-image: var(--icon-provider-light);
          }
        }
      }
    }

    &__info {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin-left: $spacing-small;

      &__title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 8px;
      }

      &__description {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
      }
    }
  }

  .progress-indicator {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto $spacing-small-plus;
    width: 50%;

    .progress-step {
      align-items: center;
      background-color: var(--light-gray);
      border-radius: 50%;
      color: var(--dark-gray);
      display: flex;
      font-weight: normal;
      height: 2rem;
      justify-content: center;
      transition: background-color 0.3s ease, color 0.3s ease;;
      width: 2rem;
  
      &.completed {
        background-color: var(--athena-blue);
        color: white;
        font-weight: bold;
      }
    }
  
    .progress-line {
      background-color: var(--light-gray);
      flex-grow: 1;
      height: 2px;
      transition: background-color 0.3s ease, color 0.3s ease;;
  
      &.completed {
        background-color: var(--athena-blue);
      }
    }
  }

  .create-org-form {
    display: flex;
    flex-direction: column;
    gap: $spacing-small;
  }
}
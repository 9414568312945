$border-light: 1px solid var(--light-gray);
$border-lighter: 1px solid var(--lighter-gray);
$border-mid-light: 1px solid var(--mid-light-gray);
$border-mid: 1px solid var(--mid-gray);
$border-dark-gray: 1px solid var(--dark-gray);
$border-darker-gray: 1px solid var(--dark-gray);
$border-black: 1px solid var(--black);

$border-transparent: 1px solid transparent;
$border-focused: 1px solid var(--athena-blue);
$border-teal: 1px solid var(--athena-light-teal-dark);
$border-error: 2px solid var(--red);

$outline: 2px solid var(--mid-light-gray);
$outline-offset: 2px;

$border-radius-small: 0.15rem;
$border-radius-medium: 0.25rem;
$border-radius-large: 0.5rem;
$border-radius-huge: 1rem;

:root {
  --box-shadow:
    0 3.5px 3.7px rgba(0, 0, 0, 0.022),
    0 8.9px 9.4px rgba(0, 0, 0, 0.031),
    0 18.1px 19.1px rgba(0, 0, 0, 0.039),
    0 37.2px 39.4px rgba(0, 0, 0, 0.048),
    0 102px 108px rgba(0, 0, 0, 0.07);

  .darkmode {
    --box-shadow: none;
  }
}

.filter-select {
  &__input-control {
    border: $border-light;
    border-radius: $border-radius-medium;

    &:focus-within {
      border: $border-focused;
    }
  }

  &__results-container {
    background-color: var(--white);
    border: $border-light;
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
    box-shadow: var(--box-shadow);
    max-height: 15rem;
    overflow-y: auto;
    width: 100%;
  }

  &__item {
    @include font--body;

    border-bottom: $border-light;
    color: var(--black);
    font-size: $font-size-small-minus;
    padding: $spacing-small-minus $spacing-small-plus;

    &:hover,
    &-selected {
      background-color: var(--lighter-gray);
    }
  }

  &__clear {
    height: 1.5rem;
    margin-right: 0.5rem;
    margin-top: -0.75rem !important;
    text-align: center;
    width: 1.5rem;

    &__svg {
      fill: var(--mid-light-gray);
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

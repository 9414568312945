.empty-state-message {
  background-color: var(--faint-gray);
  border-radius: 5px;
  box-shadow: inset 0 2px 4px var(--gray-shadow);
  color: var(--mid-gray);
  font-size: 1rem;
  font-style: italic;
  height: min-content;
  line-height: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  padding: 3rem 4rem;
  text-align: center;
  width: 65%;

  h3 {
    color: var(--dark-gray);
    margin-bottom: $spacing-tiny-plus;
  }

  span {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
}

.page-header {
  display: grid;
  grid-gap: $spacing-small;
  margin-bottom: $spacing-medium;
  
  &__backlink-and-actions {
    align-items: top;
    display: grid;
    grid-gap: $spacing-tiny;
    justify-content: space-between;
    width: 100%;


    * {
      @media (min-width: $breakpoint-small) {
        grid-row: 1;
      }
    }
  }

  &__title-and-actions {
    align-items: top;
    display: grid;
    grid-gap: $spacing-small;
    justify-content: space-between;
    width: 100%;

    * {
      @media (min-width: $breakpoint-small) {
        grid-row: 1;
      }
    }

    .lock-icon {
      padding-right: $spacing-tiny;

      svg {
        color: var(--athena-blue-darker);
        height: $spacing-small;
        width: $spacing-small;
      }
    }

    h1 {
      margin-bottom: $spacing-tiny-plus;
    }
  
    h2 {
      padding-bottom: $spacing-tiny-plus;  
    }

  }

  &__backlink {
    align-items: center;
    display: grid;
    grid-template-columns: min-content 1fr;
    margin-bottom: $spacing-small;
    min-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    @media (min-width: $breakpoint-large) {
      margin-bottom: 0;
      width: 60%;
    }

    svg {
      grid-row: 1;
      height: $spacing-small-plus;
      margin-left: -$spacing-tiny;
      min-width: $spacing-small-plus;
      width: $spacing-small-plus;
    }

    span {
      display: block;
      font-size: $font-size-small;
      grid-row: 1;
      margin-left: $spacing-tiny-plus;
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__title {
    h1 {
      display: inline;
      line-height: 1;
      margin-right: $spacing-small-minus;
      min-width: max-content;
    }
  }
  
  &__actions {
    align-items: top;
    direction: rtl;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-tiny-plus;
    justify-content: start;
    overflow: visible;
    white-space: nowrap;

    
    & > * {
      direction: ltr;

      @media (min-width: $breakpoint-small) {
        width: auto;
      }
    }
  }

  &__description {
    color: var(--mid-gray);
    font-size: $font-size-small;
    margin: 0;
  }

  &__view-toggle {
    border: $border-lighter;
    border-radius: $border-radius-large;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
    padding: 0 $spacing-small;

    h2 {
      margin: 0;
    }

    button {
      background-color: transparent;
      border-bottom: solid 2px transparent;
      border-radius: 0;
      color: var(--mid-gray);
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      height: min-content;
      outline: none;
      padding: $spacing-small-minus $spacing-small-plus;
      width: auto;
  
      &:hover {
        background-color: var(--lighter-gray);
        border-bottom-color: var(--lighter-gray);
        transition: background-color 0.3s, border-bottom-color 0.3s;
      }
  
      &.active-view {
        border-bottom-color: var(--athena-blue);
        color: var(--athena-blue);
      }
    }
  }  
}

.footer {
  @include flex-row;

  align-items: center;
  background-color: var(--white);
  border-top: $border-light;
  bottom: 0;
  color: var(--mid-gray);
  font-family: $font-family-tertiary;
  font-size: $font-size-tiny;
  font-weight: $font-weight-light;
  justify-content: space-between;
  left: 0;
  line-height: $line-height-medium-plus;
  padding: $spacing-tiny $spacing-small;
  right: 0;
  z-index: $z-index-level-3;

  @media (min-width: $breakpoint-large) {
    padding: $spacing-small $spacing-large;
  }

  a, span {
    color: var(--mid-gray);
    font-size: $font-size-tiny;
  }

  &__left {
    width: 45%;
  }

  &__center {
    padding: 0 $spacing-tiny;
    text-align: center;
  }

  &__right {
    text-align: right;
    width: 45%;
    
    &__links {
      @include flex-row;

      justify-content: flex-end;

      a {
        margin-left: $spacing-tiny-plus;
      }
    }
  }
}

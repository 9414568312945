button,
.button {
  @include flex-row;

  align-items: center;
  background-color: var(--athena-blue);
  border: none;
  border-radius: $border-radius-medium;
  color: var(--white);
  cursor: pointer;
  height: min-content;
  justify-content: center;
  padding:
    calc(#{$spacing-small} - 2px) $spacing-small-plus $spacing-small
    $spacing-small-plus;

  &:hover {
    background-color: var(--athena-blue-dark);
    text-decoration: none;
  }

  &.justify-start {
    justify-self: flex-start;
  }

  &.justify-end {
    justify-self: flex-end;
  }

  &.small {
    padding:
      calc(#{$spacing-small-minus} - 1px) $spacing-small
      $spacing-small-minus $spacing-small;
  }

  &.extra-small {
    padding: $spacing-tiny $spacing-small-minus;
  }

  &.bold {
    font-weight: $font-weight-medium;
  }

  &.small-text {
    font-size: $font-size-small-minus;
  }

  &.minimal {
    background-color: transparent;
    color: var(--athena-blue);
    padding: $spacing-small-minus $spacing-small;

    &:hover {
      color: var(--athena-blue-darker);
    }
  }

  &.outline {
    background-color: transparent;
    border: $border-mid-light;
    color: var(--athena-blue);
    padding: $spacing-small-minus $spacing-small;

    &:hover {
      background-color: var(--athena-blue-lighter);
    }
  }

  &.tiniest {
    border-radius: $border-radius-large;
    font-size: $font-size-tiny;
    padding: $spacing-tiny $spacing-tiny-plus;
    padding: calc(#{$spacing-tiny} - 1px) $spacing-tiny-plus;

  }

  &.circular {
    border-radius: 100%;
    height: $spacing-medium;
    padding: 0;
    width: $spacing-medium;

    svg {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  &.gray-text {
    color: var(--mid-gray);
  }

  &.no-padding {
    padding: 0;
  }

  &.red-text {
    color: var(--red);

    &:hover {
      color: var(--red-darker);
    }
  }

  &.darker-gray-text {
    color: var(--darker-gray);

    &:hover {
      color: var(--black);
    }
  }

  &.gray-icon {
    background-color: unset;

    svg {
      color: var(--mid-gray);
    }

    &:hover {
      background-color: var(--lighter-gray);
    }
  }

  &.black-icon {
    svg {
      color: var(--black);
    }
  }

  &.red-background {
    background-color: var(--red);

    &:hover {
      background-color: var(--red-dark);
    }
  }

  &.mid-gray-background {
    background-color: var(--mid-gray);

    &:hover {
      background-color: var(--dark-gray);
    }
  }

  &.mid-light-gray-background {
    background-color: var(--mid-light-gray);

    &:hover {
      background-color: var(--light-gray);
    }
  }

  &.lighter-gray-background {
    background-color: var(--lighter-gray);

    &:hover {
      background-color: var(--light-gray);
    }
  }

  &.with-icon {
    @include flex-row;

    &.small-icon {
      svg {
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    &.small-text {
      span {
        font-size: $font-size-small-minus;
      }
    }

    span {
      margin-left: $spacing-tiny-plus;
    }

    &.small svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &.disabled {
    background-color: var(--mid-gray);
    cursor: unset;

    &:hover {
      background-color: var(--mid-gray);
    }

    &.outline {
      background-color: transparent;
      border: $border-light;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &.disabled-text {
    color: var(--mid-gray);
    cursor: unset;

    &:hover {
      color: var(--mid-gray);
    }

    &.outline {
      color: var(--light-gray);

      &:hover {
        color: var(--light-gray);
      }
    }
  }

  .theme-toggle-icon {
    line-height: 0.8rem;
    position: relative;
    top: 0.1rem;

    svg {
      height: 0.8rem !important;
      width: 0.8rem !important;

      @media (min-width: $breakpoint-small) {
        height: 0.8rem !important;
        width: 0.8rem !important;
      }
    }
  }

  .theme-toggle-text {
    line-height: 0.8rem;
  }

}

.segment-buttons {
  display: flex;
  justify-content: center;
  margin: 0;

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + span {
    background-color: var(--faint-gray);
    border: 1px solid var(--mid-light-gray);
    font-size: $font-size-tiny-plus;
    padding: $spacing-tiny $spacing-small;
    transition: background-color 0.3s, border-color 0.3s;
  }

  label:first-child input[type="radio"] + span {
    border-radius: $border-radius-huge 0 0 $border-radius-huge;
    padding-left: $spacing-small-plus;
  }

  label:last-child input[type="radio"] + span {
    border-radius: 0 $border-radius-huge $border-radius-huge 0;
    padding-right: $spacing-small-plus;
  }

  label:not(:first-child) input[type="radio"] + span {
    border-left: none;
  }

  input[type="radio"]:checked + span {
    background-color: var(--athena-blue);
    border-color: var(--athena-blue);
    color: var(--faint-gray);
  }
}
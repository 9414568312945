.home {
  display: grid;
  grid-gap: $spacing-large-plus;

  &__header {
    display: grid;
    grid-gap: $spacing-huge;
  }

  &__header-text {
    display: grid;
    grid-gap: $spacing-small;
  }

  h2.subheading {
    font-family: $font-family-heading-primary;
    font-size: $font-size-large-minus;

    @media (min-width: $breakpoint-medium) {
      font-size: $font-size-large;
    }
  }
}

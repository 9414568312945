.group {
  &__discussions-view {
    &__create-button {
      display: flex;
      justify-content: flex-end;

      .button {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
      }
    }
  }

  &__items-view, &__discussions-view, &__members-view {
    display: grid;
    grid-gap: $spacing-small;

    &__list-container {
      display: grid;
      grid-gap: 0;

      &__heading {
        color: var(--athena-blue);
        display: inline;
        font-size: $font-size-small-plus;
    
        @media (min-width: $breakpoint-medium) {
          font-size: $font-size-medium;
        }    
      }

      &.subheading-only &__subheading {
        padding-top: $spacing-small;
      }

      &__subheading {
        color: var(--athena-orange);
        font-size: $font-size-small;

        .organization-location {
          font-size: $font-size-small;
          font-weight: $font-weight-light;
        }
      }

      &__list {
        display: grid;
        grid-gap: $spacing-tiny;
        padding: $spacing-small $spacing-medium;

        @media (min-width: $breakpoint-medium) {
          grid-gap: $spacing-small;
        }

        &__member {
          border-bottom: $border-light;
          padding: 0 0 $spacing-tiny-plus 0;
      
          @media (min-width: $breakpoint-medium) {
            padding: 0 0 $spacing-small 0;
          }

          &__name, &__name a {
            color: var(--athena-blue);
            display: inline;
            font-size: $font-size-small;
        
            @media (min-width: $breakpoint-medium) {
              font-size: $font-size-small-plus;
            }
          }

          &__title {
            color: var(--mid-gray);
            font-size: $font-size-small-minus;
            font-weight: $font-weight-light;

            @media (min-width: $breakpoint-medium) {
              font-size: $font-size-small;
            }
          }

          &__org {
            color: var(--athena-orange);
            font-size: $font-size-tiny-plus;

            .organization-name {
              font-size: $font-size-small-minus;
              font-weight: $font-weight-regular;
            }

            .organization-location {
              font-size: $font-size-tiny-plus;
              font-weight: $font-weight-light;
            }    
          }
        }

        &__item, &__discussion {
          border-bottom: $border-light;
          padding: 0 0 $spacing-tiny 0;
      
          @media (min-width: $breakpoint-medium) {
            padding: 0 0 $spacing-small 0;
          }

          &__title {
            a {
              color: var(--black);
              font-size: $font-size-small-minus;
              font-weight: $font-weight-medium;
        
              &:hover {
                color: var(--athena-blue);
                text-decoration: none;
              }
        
              @media (min-width: $breakpoint-medium) {
                font-size: $font-size-small-plus;
              }
            }            
          }
  
          &__meta {
            @include font--meta;

            align-items: center;
            display: grid;
            grid-gap: $spacing-small-minus;
            
            a {
              font-size: inherit;
            }

            span {
              font-size: inherit;
            }
        
            @media (min-width: $breakpoint-medium) {
              grid-gap: $spacing-small;
              grid-template-columns: auto auto;
              justify-content: space-between;
            }

            .emphasized {
              color: var(--dark-gray);
              font-weight: $font-weight-regular;            
            }
          }
        }
      }
    }
  }
}

.profile {
  display: grid;
  grid-gap: $spacing-medium;

  &__org-and-title {
    margin-top: -1.25rem;
  }

  &__items-container {
    display: grid;
    grid-gap: $spacing-medium;
    grid-row: 2 / 2;
    grid-template-rows: min-content auto;

    @media (min-width: $breakpoint-large) {
      grid-row: unset;
    }
  }

  &__section-container {
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: $spacing-medium;
  }

  &__section {
    display: grid;
    grid-gap: $spacing-small-minus;
  }

  &__topics-list {
    display: grid;
    grid-gap: $spacing-large-plus;
  }

  &__topic {
    display: grid;
    grid-template-rows: min-content auto;

    a {
      width: fit-content;
    }
  }

  .items-list__subtopics-list {
    grid-gap: $spacing-medium;
  }

  &__group {
    .lock-icon {
      margin-right: $spacing-tiny-plus;

      svg {
        height: $spacing-small;
        width: $spacing-small;
      }
    }
  }

  &__group-item-list {
    display: grid;
    grid-gap: $spacing-tiny-plus;
  }

  a.profile__group-view {
    margin-right: $spacing-tiny;
  }

  a.profile__group-edit {
    display: inline-flex;

    svg {
      height: $spacing-small;
      width: $spacing-small;
    }
  }
}

.item {
  @mixin pills {
    color: var(--dark-gray);
    display: inline-block;
    font-weight: $font-weight-regular;
    margin: 0 $spacing-small $spacing-tiny-plus 0;
    white-space: nowrap;

    a {
      color: var(--dark-gray);
    }
  }

  h1 {
    font-size: $font-size-large;
  }

  &__content-and-comments {
    align-items: start;
    display: grid;
    grid-gap: $spacing-medium-minus;
    margin: 0 auto;

    @media (min-width: $breakpoint-large) {
      grid-gap: $spacing-large-plus;
    }
  
    @media (min-width: $breakpoint-huge-minus) {
      grid-template-columns: 5fr 3fr;
      max-width: 90rem;
    }  
  }

  &__tags {
    display: grid;
    grid-gap: $spacing-tiny;

    @media (min-width: $breakpoint-small) {
      grid-auto-rows: min-content;
      justify-content: space-between;

      * {
        grid-row: 1;
      }
    }
  }

  &__header-requesting-feedback {
    background-color: var(--athena-light-teal);
    color: var(--black);
    font-size: $font-size-small-minus;
    font-weight: $font-weight-regular;
    height: min-content;
    margin: 0 0 $spacing-small-plus 0;
    padding: $spacing-tiny-minus $spacing-tiny;
    white-space: nowrap;
    width: min-content;

    @media (min-width: $breakpoint-huge-minus) {
      display: none;
    }
  }

  &__header-public-problem-flag {
    color: var(--black);
    display: flex;
    margin-top: $spacing-small-minus;

    svg {
      color: var(--red);
      margin-right: $spacing-small-minus;
    }
  }

  &__pill-list {
    @include flex-row;

    flex-wrap: wrap;
    margin-bottom: $spacing-small-minus;
  }

  &__subtopic {
    @include font--meta;
    @include pills;

    background-color: var(--lighter-gray);
    padding: 0 $spacing-tiny;

    a {
      font-size: $font-size-tiny-plus;

      @media (min-width: $breakpoint-small) {
        font-size: $font-size-small-minus;
      }
    }
  }

  &__hashtag {
    @include pills;

    align-items: center;
    background-color: var(--lighter-gray);
    display: flex;
    font-size: $font-size-small;
    padding-left: $spacing-tiny;
  }

  &__hashtag-button--remove {
    background: none;
    color: var(--dark-gray);
    margin: 0;
    padding: $spacing-tiny;

    &:hover {
      background: none;
      color: var(--athena-blue);
    }
  }

  &__hashtag-input-container--outer {
    margin-bottom: $spacing-medium;
  }

  &__hashtag-input-container--inner {
    margin-bottom: $spacing-tiny-plus;
  }

  &__reporting-prompt {
    background-color: var(--athena-blue);
    display: flex;
    justify-content: space-between;
    padding: $spacing-small;

    &-close-button {
      height: 1.5rem;
      margin: 0;
      padding: 0;
    }

    &-text {
      margin-right: 0.5rem;
      max-width: 45rem;
    }
  }


  &__content {
    display: grid;
    grid-gap: $spacing-medium;

    @media (min-width: $breakpoint-large) {
      grid-gap: $spacing-large-minus;
    }
  }

  &__section {
    display: grid;
    grid-gap: $spacing-small-minus;

    h2 {
      color: var(--athena-orange);
      font-size: $font-size-small-minus;

      @media (min-width: $breakpoint-medium) {
        font-size: $font-size-small;
      }
    }

    p,
    li,
    a {
      font-size: $font-size-small-minus;

      @media (min-width: $breakpoint-medium) {
        font-size: $font-size-small;
      }
    }
  }

  &__section-content {
    display: grid;
    grid-gap: $spacing-small-plus;

    ul li {
      list-style: disc;
      list-style-position: outside;
      margin-left: 1rem;
    }
  }

  &__link-list {
    display: grid;
    grid-gap: $spacing-tiny;
  }

  &__edit-and-verify {
    @include flex-column;

    align-items: flex-start;
    margin-top: $spacing-small-minus;

    @media (min-width: $breakpoint-small) {
      @include flex-row;

      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &__verified-badge-container {
    position: relative;

    p {
      font-size: $font-size-tiny-plus;

      @media (min-width: $breakpoint-small) {
        font-size: $font-size-small-minus;
      }
    }
  }

  &__verified-badge {
    align-items: center;
    border: $border-light;
    border-color: var(--athena-blue-dark);
    border-radius: $border-radius-medium;
    display: flex;
    justify-content: center;
    margin-top: $spacing-small;
    padding: 0 $spacing-tiny-plus 0 $spacing-tiny-plus;

    @media (min-width: $breakpoint-small) {
      margin-top: unset;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus + .item__verifying-authorities {
      display: block;
    }

    p {
      border-right: 1px solid var(--athena-blue-dark);
      color: var(--athena-blue-dark);
      margin-right: $spacing-tiny-plus;
      padding: $spacing-tiny-minus $spacing-tiny-plus $spacing-tiny-minus $spacing-tiny-plus;
      white-space: nowrap;
    }

    svg {
      color: var(--athena-blue-dark);
      height: $spacing-small;
      width: $spacing-small;
    }
  }

  &__verifying-authorities {
    background-color: var(--white);
    border: $border-light;
    border-radius: $border-radius-medium;
    box-shadow: var(--box-shadow);
    display: none;
    margin-top: $spacing-small-minus;
    padding: $spacing-tiny-plus $spacing-small $spacing-tiny-plus $spacing-small;
    position: absolute;
    width: $spacing-huge-plus * 3;
    z-index: $z-index-level-4;

    &:focus-within, &:hover {
      display: block;
    }

    @media (min-width: $breakpoint-small) {
      right: 0;
    }

    p {
      color: var(--dark-gray);
    }
  }

  &__license p {
    color: var(--mid-light-gray);
    font-size: $font-size-tiny-plus;

    a {
      color: var(--mid-lightish-gray);
      font-size: $font-size-tiny-plus;
    }
  }

  &__form__license p {
    color: var(--mid-gray);
    font-size: $font-size-small;
    margin-top: $spacing-small;
    max-width: 25rem;

    a {
      color: var(--mid-dark-gray);
      font-size: $font-size-small;
      white-space: nowrap;
    }
  }

}

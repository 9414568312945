.navigation-bar {
  @include flex-row;

  align-items: center;
  background-color: var(--white);
  border-bottom: $border-light;
  height: $spacing-large;
  justify-content: space-between;
  left: 0;
  padding-left: $spacing-small;
  right: 0;
  top: 0;
  z-index: $z-index-level-3;

  @media (min-width: $breakpoint-small) {
    padding-left: $spacing-medium;
  }

  &__left {
    @include flex-row;

    align-items: center;
    height: 100%;
    width: 90%;
  }

  &__logo {
    @include flex-row;

    padding: 10px 0;

    &__image {  
      background-image: url("/src/assets/athena-logo-blue.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 30px;
      width: 133px;
    }
  }

  &__announcement-banner {
    background-color: var(--athena-blue-lightest);
    border-bottom: 1px solid var(--athena-blue-light);
    color: var(--dark-gray);
    display: none;
    font-size: $font-size-small-minus;
    padding: $spacing-tiny-plus 0;
    text-align: center;
    width: 100%;

    &:focus {
      outline-offset: -3px;
    }

    @media (min-width: $breakpoint-small) {
      display: block;
    }

    &:visited {
      color: var(--dark-gray);
    }

    &:hover {
      background-color: var(--athena-blue-lighter);
      color: var(--darker-gray);
      text-decoration: none;
    }
  }
}

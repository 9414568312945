.form {
  display: grid;
  grid-gap: $spacing-medium-plus;

  form {
    display: grid;
    grid-gap: $spacing-medium;

    &.form__large-grid-gap {
      grid-gap: $spacing-large-minus;
    }
  }

  input {
    width: 100%;

    &:disabled {
      background-color: var(--light-gray); /* Light grey background */
      color: var(--dark-gray); /* Grey text color */
      cursor: not-allowed; /* Change cursor to indicate it's not clickable */
      pointer-events: none; /* Prevent interaction */
      user-select: none; /* Prevent text selection */
    }

    &.input-error {
      border-left: $border-error;
    }
  }

  input[type="checkbox"] {
    border: $border-mid-light;
    height: 1.5rem;
    width: 1.5rem;

    &:checked {
      background-color: var(--athena-blue-dark);

      &::after {
        color: var(--white);
        content: '✔';
        font-size: 1rem;
        left: 0.25rem;
        position: relative;
        top: 0.15rem;
        transform: translate(-50%, -50%);
      }
    }
  }

  textarea {
    height: 4rem;
    width: 100%;
  }

  .textarea--large {
    height: 8rem;
  }

  .textarea--larger {
    height: 12rem;
  }

  &__label-with-buttons {
    display: flex;
    justify-content: space-between;
  }

  &__submit-button {
    width: fit-content;

    &:disabled {
      background-color: var(--mid-light-gray); /* Light grey background */
      color: var(--faint-gray); /* Grey text color */
      cursor: not-allowed; /* Change cursor to indicate it's not clickable */
      pointer-events: none; /* Prevent interaction */
      user-select: none; /* Prevent text selection */
    }

  }

  &__input-and-hint {
    @media (min-width: $breakpoint-huge-minus) {
      @include flex-row;

      input,
      textarea {
        width: 40rem;
      }

      .hint-container {
        background-color: transparent;
        max-width: 25rem;
        padding: 0 $spacing-small;
      }

      .select-container {
        max-width: 40rem;
      }
    }

    .select-container {
      width: 100%;
    }
  }

  &__field-list-and-label {
    display: grid;
    grid-gap: $spacing-small;

    label {
      margin-bottom: 0;
    }    
  }

  &__field-list {
    display: grid;
    grid-gap: $spacing-small;
  }

  &__field-list--large-gap {
    display: grid;
    grid-gap: $spacing-medium-minus;
  }

  &__field-list--huge-gap {
    display: grid;
    grid-gap: $spacing-large-plus;
  }

  &__field-list--large-gap-small-fields {
    display: grid;
    grid-gap: $spacing-small-plus;

    @media (min-width: $breakpoint-huge-minus) {
      width: 40rem;
    }

    select {
      font-size: $font-size-small;
      height: $spacing-medium-plus;
    }

    .select-container::after {
      top: 0.55rem;
    }

    input {
      font-size: $font-size-small;
      height: $spacing-medium-plus;
    }
  }

  &__field-list-field-group--horizontal {
    display: grid;
    grid-gap: $spacing-small;

    @media (min-width: $breakpoint-medium) {
      grid-gap: $spacing-small;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__field-list-field-group--horizontal-right-small {
    display: grid;
    grid-gap: $spacing-small;

    @media (min-width: $breakpoint-medium) {
      grid-template-columns: 7fr minmax(10rem, 3fr);
    }
  }

  &__field-list-field-group--vertical {
    display: grid;
    grid-gap: $spacing-small;
  }

  &__field-list-field-group--binary-radio {
    clear: both;
    display: grid;
    grid-gap: $spacing-small;
    grid-template-columns: min-content min-content;
    position: relative;
  }

  @mixin form__field-list-item--shared {
    align-items: center;
    display: grid;
  }

  &__field-list-item {
    @include form__field-list-item--shared;

    grid-template: min-content 1fr / 8fr 1fr;

    .form__field-list-item-input {
      grid-area: 2 / 1 / 2 / 1;
    }

    button {
      margin-left: $spacing-small;
    }
  }

  &__field-list-item--with-label {
    @include form__field-list-item--shared;

    border-bottom: $border-light;
    grid-template-columns: 15fr 1fr;
    padding-bottom: $spacing-medium;

    button {
      align-self: flex-end;
      margin: 0 0 0.4rem $spacing-small;
    }
  }

  &__field-list-item--remove-top-right {
    @include form__field-list-item--shared;

    background-color: var(--lightest-gray);
    border: $border-mid-light;
    border-radius: $border-radius-medium;
    padding: $spacing-small-plus;
    position: relative;

    .label-secondary {
      color: var(--dark-gray);
    }

    button {
      position: absolute;
      right: -$spacing-small;
      top: -$spacing-small;
    }
  }

  &__requesting-feedback-container {
    background-color: var(--athena-light-teal);
    border: $border-teal;
    max-width: 40rem;
    padding: $spacing-small $spacing-medium-minus;

    .radio-container--binary {
      label {
        background-color: var(--athena-light-teal);
      }
    }

    legend {
      float: left;
    }
  }

  &__add-button {
    margin-top: $spacing-tiny-plus;

    button {
      &:focus {
        outline-offset: 0;
      }
    }
  }

  &__add-resources-empty-list {
    margin-top: 0;
  }

  &__input-with-error {
    border-left: $border-error;
    display: grid;
    grid-gap: $spacing-small;
    padding-left: $spacing-medium;
  }

  &__error-message {
    color: var(--red)
  }

  &__step {
    display: none;

    &.active-step {
      display: grid
    }
  }

  &__button-group {
    display: flex;
    gap: $spacing-small;
    justify-content: space-between;
    margin-top: $spacing-large;
  
    button {
      height: 3rem;
      width: 6rem;
    }

    button:only-of-type {
      margin-left: auto;
    }
  }

  &__segment-buttons {
    display: flex;
    gap: $spacing-tiny;
    justify-content: flex-end;

    button {
      font-size: $font-size-small-minus;
      height: 1.5rem;
      width: auto;

      &:disabled, &.disabled, &.disabled:hover {
        background-color: var(--mid-light-gray);
        color: var(--faint-gray);
        cursor: not-allowed; 
        pointer-events: none;
        user-select: none;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  margin: unset;
}

h1 {
  @include font--heading-primary;

  &.item-title {
    @include font--heading-item-title;
  }

  &.discussion-title {
    @include font--heading-discussion-title;
  }

  &.emphasized {
    font-size: $font-size-large-plus;

    @media (min-width: $breakpoint-medium) {
      font-size: $font-size-huge;
    }
  }
}

h2 {
  @include font--heading-secondary;

  color: var(--mid-gray);
  font-size: $font-size-medium;
  font-weight: $font-weight-regular;

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-medium-plus;
  }
}

h3 {
  @include font--heading-secondary;

  font-size: $font-size-small;
}

h4 {
  @include font--heading-secondary;

  font-size: $font-size-small;
}

h5,
h6 {
  @include font--heading-secondary;

  font-size: $font-size-small-minus;
}

p,
li,
span {
  @include font--body;

  color: inherit;
  word-break: break-word;
}

p {
  color: var(--black);
  margin-block-end: 0;
  margin-block-start: 0;
}

br {
  display: block;
  margin: $spacing-small-minus 0;
}

ul,
ol {
  color: var(--black);
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  @include font--tertiary;

  color: var(--athena-blue);
  text-decoration: none;

  &:visited {
    color: var(--athena-blue);
  }

  &:hover {
    color: var(--athena-blue-darker);
    text-decoration: underline;
  }

  &.button {
    color: var(--white);
    white-space: nowrap;
    width: min-content;

    &:hover {
      text-decoration: none;
    }
  }

  p &,
  ul &,
  li & {
    color: var(--athena-blue);
  }

  p & {
    text-decoration: underline;
  }

  h1 &,
  h2 & {
    color: inherit;
  }
}

label,
.label,
legend {
  @include font--label;
}

input,
select,
textarea {
  @include font--tertiary;

  color: var(--darker-gray);
}

button {
  @include font--tertiary;
}

button,
.button {
  line-height: $line-height-medium-plus;
}

.font--meta {
  @include font--meta;

  a {
    font-size: inherit;
  }
}

.font--bold {
  font-weight: $font-weight-medium;
}

.font--meta-emphasized {
  color: var(--dark-gray);
  font-weight: $font-weight-regular;
}

.font--error {
  @include font--meta;

  color: var(--red);
  font-weight: $font-weight-regular;
}

.h1--emphasized {
  font-size: $font-size-large-plus;

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-huge;
  }
}

.h2--subheading {
  color: var(--mid-gray);
  font-family: $font-family-heading-primary;
  font-size: $font-size-large-minus;

  @media (min-width: $breakpoint-medium) {
    font-size: $font-size-large;
  }
}

.paragraph-container {
  * {
    font-size: inherit;
    white-space: pre-wrap;
  }

  .item__section &,
  .discussion__body & {
    display: grid;
    grid-gap: $spacing-small;
  }

  em p,
  strong p {
    display: inline;
    grid-gap: 0;
  }

  ul li {
    list-style: disc;
    list-style-position: outside;
    margin-left: 1rem;
  }

  ol li {
    list-style: decimal;
    list-style-position: outside;
    margin-left: 1rem;
  }
}

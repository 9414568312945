@mixin input--shared {
  background-color: var(--white);
  border: $border-light;
  border-radius: $border-radius-medium;
  height: $spacing-large;
  padding: 0 $spacing-small;
  text-overflow: ellipsis;

  &:focus {
    border: $border-focused;
    outline: none;
  }

  &::placeholder {
    color: var(--mid-gray);
    opacity: 1;
  }
}

input {
  @include input--shared;

  -webkit-appearance: none;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px var(--white) inset;
    caret-color: var(--black);
    -webkit-text-fill-color: var(--black);

    &::first-line {
      @include font--tertiary;
    }
  }

  &[type="search"] {
    -webkit-appearance: none;
  }
}

.input-with-icon {
  @include input--shared;

  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  padding-right: 0;

  &:focus-within {
    border: $border-focused;
    outline: none;
  }

  input {
    background-color: unset;
    border: none;
    border-radius: unset;
    height: 100%;
    padding-left: $spacing-small-minus;
  }

  svg {
    color: var(--mid-gray);
    width: $spacing-small-plus;
  }
}

label,
legend {
  color: var(--black);
  display: block;
  margin-bottom: $spacing-small-minus;

  &.label-secondary {
    color: var(--mid-gray);
    font-size: $font-size-small-minus;
    font-weight: $font-weight-regular;
  }
}

.input-with-label--horizontal {
  align-items: center;
  display: grid;
  grid-gap: $spacing-tiny;

  label {
    margin-bottom: 0;
    white-space: nowrap;
  }

  @media (min-width: $breakpoint-medium) {
    grid-gap: $spacing-small;
    grid-template-columns: min-content auto;
  }
}

fieldset {
  border: none;
  padding: 0;
}

.field-description {
  pre {
    @include font--meta;

    color: var(--dark-gray);
    line-height: $line-height-large;
  }
}

.hint-container {
  background-color: var(--faint-gray);
  border-bottom-left-radius: $border-radius-medium;
  border-bottom-right-radius: $border-radius-medium;
  padding: $spacing-small-minus $spacing-small;

  svg {
    color: var(--athena-orange);
    display: block;
    width: $spacing-small;
  }
}

.select-container {
  background-color: var(--white);
  position: relative;

  &--small-text {
    select {
      font-size: $font-size-small-minus;

      @media (min-width: $breakpoint-small) {
        font-size: $font-size-small;
      }
    }
  }

  select {
    appearance: none;
    background: none;
    border: $border-light;
    border-radius: $border-radius-medium;
    height: $spacing-large;
    padding: 0 $spacing-large-minus 0 $spacing-small;
    width: 100%;

    &:focus {
      border: $border-focused;
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &::after {
    background-image: url("/src/assets/icons/chevron-down-blue.svg");
    background-position: center;
    background-repeat: no-repeat;
    color: var(--athena-blue);
    content: "";
    height: $spacing-small-plus;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: 0.9rem;
    width: $spacing-small-plus;
  }
}

option {
  font-family: sans-serif;
}

.radio-container--binary {
  label {
    background-color: var(--faint-gray);
    border: $border-mid-light;
    border-radius: $border-radius-medium;
    color: var(--dark-gray);
    cursor: pointer;
    font-weight: $font-weight-regular;
    margin: $spacing-small-minus 0 0 0;
    padding: $spacing-tiny $spacing-small;
    user-select: none;
  }

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  input:checked ~ label {
    background-color: var(--white);
    border: $border-black;
    color: var(--black);
  }

  input:focus ~ label {
    outline: $outline;
    outline-offset: $outline-offset;
  }
}

.radio-container--default {
  label {
    color: var(--mid-gray);
    cursor: pointer;
    font-weight: $font-weight-regular;
    user-select: none;
  }

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  input:checked ~ label {
    color: var(--black);
    font-weight: $font-weight-medium;
  }

  input:focus ~ label {
    outline: $outline;
    outline-offset: $outline-offset;
  }
}

textarea {
  -webkit-appearance: none;
  background-color: var(--white);
  border: $border-light;
  border-radius: $border-radius-medium;
  line-height: $line-height-medium;
  min-height: 6rem;
  padding: $spacing-small $spacing-small;
  resize: vertical;
  vertical-align: top;

  &:focus {
    border: $border-focused;
    outline: none;
  }

  &::placeholder {
    line-height: $line-height-medium;
  }
}

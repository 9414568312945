.navigation-menu {
  @include flex-row;

  align-items: center;
  height: 100%;

  @mixin navigation-menu__item-shared {
    cursor: pointer;
    line-height: $spacing-small-minus;
    outline: none;
    white-space: nowrap;

    a,
    button {
      color: var(--dark-gray);
      display: block;
      font-size: $font-size-small;
      line-height: $line-height-small-minus;
      padding: $spacing-small $spacing-small-plus;
    }

    &:hover {
      a,
      .navigation-menu__item-with-subitems-inner,
      button {
        text-decoration: none;
      }
    }

    &:last-child {
      margin-right: $spacing-tiny-plus;

      @media (min-width: $breakpoint-small) {
        margin-right: $spacing-small;
      }
    }
  }

  &__item {
    @include navigation-menu__item-shared;

    height: 100%;

    a {
      @include flex-row;

      align-items: center;
      height: 100%;
      padding: 0 $spacing-small-minus;
      white-space: nowrap;

      @media (min-width: $breakpoint-small) {
        padding: 0 $spacing-small;
      }
      
      &.active {
        background-color: var(--lightest-gray);
      }

      &:hover {
        background-color: var(--lightest-gray);
      }

    }

    // add a class for a red badge icon that can show a number in it to be displayed on top of an icon
    &__badge {
      @include flex-row;

      align-items: center;
      background-color: var(--red);
      border-radius: 50%;
      color: var(--white);
      font-size: $font-size-tiny;
      font-weight: $font-weight-medium;
      height: 1.25rem;
      justify-content: center;
      position: relative;
      right: 0.75rem;
      top: -0.5rem;
      width: 1.25rem;
    }

    &__invite-others {
      @media (width <= $breakpoint-small) {
        display: none;
      }
    }
  }

  &__item-with-subitems {
    @include navigation-menu__item-shared;

    height: 100%;
    position: relative;

    &:focus-within,
    &:hover {
      background-color: var(--lightest-gray);
      outline: none;
    }

    .navigation-menu__item-with-subitems-inner {
      @include flex-row;

      align-items: center;
      height: 100%;
      padding: 0 $spacing-small;

      @media (min-width: $breakpoint-small) {
        padding: 0 $spacing-small-plus;
      }
    }

    .navigation-menu__item-icon {
      @include flex-row;

      align-items: center;

      @media (min-width: $breakpoint-small) {
        display: none;
      }
    }

    .navigation-menu__item-text {
      display: none;
      font-size: $font-size-small;

      @media (min-width: $breakpoint-small) {
        @include flex-row;
      }
    }
  }

  &__add-group {
    &:not(:first-child) {
      margin-top: $spacing-tiny-plus;
    }
  }

  &__header {
    color: var(--athena-orange);
    cursor: default;
    font-size: $font-size-small-minus;
    padding:
      $spacing-tiny $spacing-small-minus $spacing-tiny-minus
      $spacing-small-minus;
    text-align: right;
  }

  &__item-with-subitems-links {
    background-color: var(--white);
    border: $border-light;
    border-bottom-left-radius: $border-radius-medium;
    border-bottom-right-radius: $border-radius-medium;
    display: none;
    min-width: 10rem;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: $z-index-level-2;

    .navigation-menu__item-with-subitems:focus,
    .navigation-menu__item-with-subitems:focus-within & {
      @include flex-column;
    }

    li:not(.navigation-menu__header) {
      white-space: nowrap;

      &:hover,
      &:focus-within {
        background-color: var(--lightest-gray);
      }

      button {
        align-items: unset;
        background-color: unset;
        border-radius: unset;
        display: unset;
        justify-content: unset;
        line-height: $line-height-large-minus;
        width: 100%;
      }

      a,
      button {
        display: block;
        font-size: $font-size-small-minus;
        line-height: $line-height-small-minus;
        padding: $spacing-small-minus;
        text-align: right;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

/*
  elm-hot creates an additional div wrapper around the app to make HMR possible.
  This could break styling in development mode if you are using Elm UI.

  More context in the issue:
    https://github.com/halfzebra/create-elm-app/issues/320
*/
[data-elm-hot="true"] {
  height: inherit;
}

/* Colors are imported first because the other variable files use them */
@import "variables/colors.scss";
@import "variables/borders.scss";
@import "variables/iconography.scss";
@import "variables/spacing.scss";
@import "variables/layout.scss";
@import "variables/typography.scss";
@import "global/badges.scss";
@import "global/buttons.scss";
@import "global/container.scss";
@import "global/forms.scss";
@import "global/layout.scss";
@import "global/typography.scss";
@import "components/action-button.scss";
@import "components/comments-section.scss";
@import "components/filter-select.scss";
@import "components/footer.scss";
@import "components/empty-state.scss";
@import "components/delete-confirmation.scss";
@import "components/form.scss";
@import "components/items-list.scss";
@import "components/loading.scss";
@import "components/membership.scss";
@import "components/meta-label.scss";
@import "components/navigation-bar.scss";
@import "components/navigation-menu.scss";
@import "components/page-header.scss";
@import "components/search-bar.scss";
@import "components/survicate.scss";
@import "components/topics-list.scss";
@import "pages/account.scss";
@import "pages/discussion.scss";
@import "pages/edit-profile.scss";
@import "pages/group.scss";
@import "pages/home.scss";
@import "pages/invitations.scss";
@import "pages/item.scss";
@import "pages/notifications.scss";
@import "pages/plans.scss";
@import "pages/profile.scss";
@import "pages/signup.scss";
@import "pages/topic.scss";

.delete-confirmation {
  &__request-delete {
    align-items: flex-end;
    color: var(--red);
    display: inline-grid;
    grid-auto-columns: auto;
    grid-gap: $spacing-small-minus;
    padding-bottom: $spacing-huge;

    * {
      grid-row: 1;
    }
  }
  
  &__confirm-delete {
    background-color: var(--faint-gray);
    border: $border-lighter;
    display: grid;
    grid-gap: $spacing-small;
    margin-top: $spacing-small;
    padding: $spacing-small-plus;
  }
  
  &__confirm-delete-buttons {
    align-items: center;
    display: grid;
    grid-auto-columns: max-content;
    grid-gap: $spacing-small;
  
    * {
      grid-row: 1;
      height: 100%;
    }
  }
}
